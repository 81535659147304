<template>
  <!-- <div class="wrap"> -->
  <template v-if="type === 1">
    <NuxtLink
      v-if="!targetOpenNewPage"
      :to="to"
      :class="customClass"
      :title="title"
    >
      <slot />
    </NuxtLink>
    <div
      v-else
      :class="customClass"
      :style="{ cursor: props.to !== 'javascript:;' ? 'pointer' : 'default'}"
      :title="title"
      @click="linkTo"
    >
      <slot />
    </div>
  </template>
  <template v-if="type === 2">
    <a
      :href="to"
      :target="targetOpenNewPage ? '_black' : '_self'"
      :class="customClass"
      :title="title"
    >
      <slot />
    </a>
  </template>
  <template v-if="type === 3">
    <span
      :class="customClass"
      :title="title"
    >
      <slot />
    </span>
  </template>
  <!-- </div> -->
</template>

<script setup>
const props = defineProps({
  to: {
    type: String,
    default: ''
  },
  targetType: {
    type: Number
  },
  customClass: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  targetOpenNewPage: {
    type: Boolean,
    default: false
  }
})
const type = ref(1)
const route = useRoute()
const linkTo = () => {
  if (props.to !== 'javascript:;') {
    window.open(props.to, '_blank')
  }
}
onMounted(() => {
  if (props.targetType === 9) {
    type.value = 2
  } else if (!props.targetType || props.targetType === 99) {
    type.value = 3
  } else {
    type.value = 1
  }
})
</script>

<style>
</style>
